
import { Component, Vue } from 'vue-property-decorator';
import Locale from '@/components/shared/Locale.vue';
import UserMenu from '@/components/shared/UserMenu.vue';
import WorkspaceNav from '@/components/workspaces/WorkspaceNav.vue';
import Help from '@/components/shared/Help.vue';
import { Getter, Action } from 'vuex-class';
import * as types from '@/store/types';
import { Workspace } from '@/store/workspace/models';

const namespace: string = 'user';
const workspaceNamespace = 'workspace';

@Component({
  components: {
    Locale,
    UserMenu,
    WorkspaceNav,
    Help,
  },
})
export default class Navigator extends Vue {
  @Getter(types.GET_USER, { namespace }) getUser: any;

  @Getter(types.GET_USER_WORKSPACES, { namespace: workspaceNamespace }) getWorkspaces: any;

  private showExtension: boolean = false;

  get color() {
    return this.$vuetify.theme.dark ? '#3C3C3C' : '#f5f5f5';
  }

  get isLoggedIn() {
    if (this.$route.meta) {
      return this.$route.meta.requiresAuth;
    }

    return false;
  }

  isSelected(to: string) {
    const currentPath = this.$router.currentRoute.name;
    return currentPath === to;
  }

  showWorkspaceNavButtons(): boolean {
    const { name } = this.$router.currentRoute;
    const allowToShowNav = name === 'flows'
      || name === 'flow'
      || name === 'bricks'
      || name === 'packages'
      || name === 'APIs'
      || name === 'WorkspaceSetting'
      || name === 'TypesLibrary';
    return allowToShowNav;
  }

  isUserWorkspaceOwner(name: string): boolean {
    if (name !== 'packages') {
      return true;
    }
    const { workspaceId } = this.$route.params;
    const userId = this.getUser.id;
    if (!this.getWorkspaces || !userId || !workspaceId) return false;
    const workspaceIndex = this.getWorkspaces.findIndex(
      (w: Workspace) => workspaceId === w.id && w.owner.id === userId,
    );
    return workspaceIndex !== -1;
  }

  get routes() {
    return [
      {
        name: 'Flows',
        to: 'flows',
        icon: 'mdi-sitemap',
      },
      {
        name: 'Bricks',
        to: 'bricks',
        icon: 'mdi-wall',
      },
      {
        name: 'API',
        to: 'APIs',
        icon: 'mdi-connection',
      },
      {
        name: this.$t('types_library'),
        to: 'TypesLibrary',
        icon: 'mdi-library',
      },
      {
        name: this.$t('brick_store'),
        to: 'packages',
        icon: 'mdi-shopping-outline',
      },
    ];
  }
}
