export const Integers = ['int8', 'int16', 'int32', 'int64'];

export const UIntegers = ['uint8', 'uint16', 'uint32', 'uint64'];

export const Floats = ['float16', 'float32', 'float64'];

export const AtomicTypes = ['bool', ...Floats, ...Integers, 'string', ...UIntegers, 'variant'];

export const ExtraType = ['list', 'map'];

export const ContainerType = ['record', 'object'];

export const AllTypes = [...AtomicTypes, ...ExtraType, ...ContainerType];

export const AllElementTypes = [...AtomicTypes, ...ExtraType];

export const MESSAGE_TYPE = 'messageType';

export const TypesWithElement = [...ContainerType];
