/*
 Copyright 2019-present wobe-systems GmbH

 Licensed under the Apache License, Version 2.0 (the 'License');
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an 'AS IS' BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
/*  ---------------GETTERS----------------  */

// User
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const GET_LOGIN_STATUS = 'GET_LOGIN_STATE';
export const GET_FLOW_OWNERS = 'GET_FLOW_OWNERS';
export const GET_SIGNUP_REQUEST_RESULT = 'GET_SIGNUP_REQUEST_RESULT';
export const GET_FORGET_PASSWORD_REQUEST_RESULT = 'GET_FORGET_PASSWORD_REQUEST_RESULT';
export const GET_GRAVATARS = 'GET_GRAVATARS';

// ERROR
export const GET_ERROR = 'GET_ERROR';
export const GET_ERROR_LEVEL = 'GET_ERROR_LEVEL';
export const GET_ERROR_SERVICE = 'GET_ERROR_SERVICE';

// Flow
export const GET_FLOW_NAME = 'GET_FLOW_NAME';
export const GET_FLOWS = 'GET_FLOWS';
export const GET_FLOW = 'GET_FLOW';
export const GET_FLOW_NAMES = 'GET_FLOW_NAMES';
export const GET_BRICKS = 'GET_BRICKS';
export const GET_AVAILABLE_BRICKS = 'GET_AVAILABLE_BRICKS';
export const GET_ALL_BRICKS = 'GET_ALL_BRICKS';
export const GET_BRICK_NAMES = 'GET_BRICK_NAMES';
export const GET_BRICK_TYPES = 'GET_BRICK_TYPES';
export const GET_RUN_STATE = 'GET_RUN_STATE';
export const GET_LOADING = 'GET_LOADING';
export const GET_IS_DIRTY = 'GET_IS_DIRTY';
export const GET_SORT_FLOWS_BY = 'GET_SORT_FLOWS_BY';
export const GET_PORT = 'GET_PORT';
export const GET_CONSTANTS = 'GET_CONSTANTS';
export const GET_CONNECTIONS = 'GET_CONNECTIONS';
export const GET_SOURCE_TO_BUFFER = 'GET_SOURCE_TO_BUFFER';
export const GET_UPDATE_BRICK_LOADING = 'GET_UPDATE_BRICK_LOADING';

// Bricks
export const GET_ADD_BRICK_LOADING = 'GET_ADD_BRICK_LOADING';
export const GET_ADD_BRICK_ERROR = 'GET_ADD_BRICK_ERROR';
export const GET_ADD_BRICK_SUCCESS = 'GET_ADD_BRICK_SUCCESS';
export const GET_PACKAGES = 'GET_PACKAGES';

// PLATFORM CONFIG
export const GET_PLATFORM_CONFIG = 'GET_PLATFORM_CONFIG';

// InviteUser
export const GET_INVITED_USER = 'GET_INVITED_USER';

// BRICK STORE
export const GET_ALL_PACKAGES = 'GET_ALL_PACKAGES';
export const GET_BRICKS_OF_PACKAGE = 'GET_BRICKS_OF_PACKAGE';

// WORKSPACE
export const GET_USER_WORKSPACES = 'GET_USER_WORKSPACES';
export const GET_WORKSPACE = 'GET_WORKSPACE';

// APIs
export const GET_APIS = 'GET_APIS';

// Type Library
export const GET_TYPE_LIBRARY = 'GET_TYPE_LIBRARY';
export const GET_RESOLVED_TYPES = 'GET_RESOLVED_TYPES';
export const GET_RESOLVED_TYPE = 'GET_RESOLVED_TYPE';

/*  --------------ACTIONS-----------------  */
// Flow
export const FETCH_FLOW = 'FETCH_FLOW';
export const FETCH_BRICKS = 'FETCH_BRICKS';
export const SEARCH_FLOW = 'SEARCH_FLOW';
export const SEARCH_BRICK = 'SEARCH_BRICK';
export const FETCH_FLOWS = 'FETCH_FLOWS';
export const CREATE_FLOW = 'CREATE_FLOW';
export const COPY_FLOW = 'COPY_FLOW';
export const DELETE_FLOW = 'DELETE_FLOW';
export const SET_SELECTED_FLOW = 'SET_SELECTED_FLOW';
export const FILTER_BRICKS = 'FILTER_BRICKS';
export const CHANGE_RUN_STATE = 'CHANGE_RUN_STATE';
export const SAVE_FLOW = 'SAVE_FLOW';
export const ADD_BRICK = 'ADD_BRICK';
export const ADD_BRICK_CLEAR_STATE = 'ADD_BRICK_CLEAR_STATE';
export const SET_FLOW_IS_DIRTY = 'SET_FLOW_IS_DIRTY';
export const CLEAR_FLOW_STATE = 'CLEAR_FLOW_STATE';
export const EDIT_FLOW_NAME = 'EDIT_FLOW_NAME';
export const EDIT_FLOW_DESCRIPTION = 'EDIT_FLOW_DESCRIPTION';
export const CREATE_PORT_JSON = 'CREATE_PORT_JSON';
export const ADD_BRICK_IN_FLOW = 'ADD_BRICK_IN_FLOW';
export const ADD_PORT_MAPPING = 'ADD_PORT_MAPPING';
export const DELETE_PORT_MAPPING = 'DELETE_PORT_MAPPING';
export const ADD_CONSTANT = 'ADD_CONSTANT';
export const EDIT_CONSTANT = 'EDIT_CONSTANT';
export const DELETE_CONSTANT = 'DELETE_CONSTANT';
export const VALIDATE_CONSTANT = 'VALIDATE_CONSTANT';
export const RESET_CONSTANT_VALIDATION = 'RESET_CONSTANT_VALIDATION';
export const CREATE_CONNECTION = 'CREATE_CONNECTION';
export const FETCH_CONNECTIONS = 'FETCH_CONNECTIONS';
export const DELETE_CONNECTION = 'DELETE_CONNECTION';
export const UPDATE_CONNECTION = 'UPDATE_CONNECTION';
export const CREATE_NEW_BUFFER = 'CREATE_NEW_BUFFER';
export const ADD_TO_BUFFER = 'ADD_TO_BUFFER';
export const ADD_FIELD_TO_MAP = 'ADD_FIELD_TO_MAP';
export const REMOVE_FIELD_FROM_MAP = 'REMOVE_FIELD_FROM_MAP';
export const EDIT_FIELD_IN_MAP = 'EDIT_FIELD_IN_MAP';
export const DELETE_BUFFER = 'DELETE_BUFFER';
export const VALIDATE_BUFFER_ELEMENT = 'VALIDATE_BUFFER_ELEMENT';
export const RESET_VALIDATION_BUFFER = 'RESET_VALIDATION_BUFFER';
export const UPDATE_BRICK = 'UPDATE_BRICK';
export const DELETE_BRICK = 'DELETE_BRICK';
export const CONFIGURE_APIBRICK = 'CONFIGURE_APIBRICK';
export const EXPORT_FLOW = 'EXPORT_FLOW';
export const IMPORT_FLOW = 'IMPORT_FLOW';

// BRICKS
export const DELETE_PACKAGE = 'DELETE_PACKAGE';
export const INSTALL_PACKAGE = 'INSTALL_PACKAGE';
export const DELETE_USERS_PACKAGES = 'DELETE_USERS_PACKAGES';

// PACKAGES
export const FETCH_PACKAGES = 'FETCH_PACKAGES';

// BRICK STORE
export const FETCH_ALL_PACKAGES = 'FETCH_ALL_PACKAGES';
export const FETCH_PACKAGE_BRICKS = 'FETCH_PACKAGE_BRICKS';

// USER
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SIGNUP = 'SIGNUP';
export const FETCH_LOGGED_IN_USER = 'FETCH_LOGGED_IN_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const DELETE_USER = 'DELETE_USER';
export const SET_ADMIN_RIGHT = 'SET_ADMIN_RIGHT';
export const UNSET_ADMIN_RIGHT = 'UNSET_ADMIN_RIGHT';
export const SET_UPLOAD_RIGHT = 'SET_UPLOAD_RIGHT';
export const UNSET_UPLOAD_RIGHT = 'UNSET_UPLOAD_RIGHT';
export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const RESET_SIGNUP_REQUEST = 'RESET_SIGNUP_REQUEST';
export const FETCH_GRAVATAR = 'FETCH_GRAVATAR';
export const SEND_MESSAGE_TO_ALL_USERS = 'SEND_MESSAGE_TO_ALL_USERS';
export const REMOVE_USER_STATE = 'REMOVE_USER_STATE';

// ERROR
export const SET_ERROR = 'SET_ERROR';

// PLATFORM CONFIG
export const FETCH_PLATFORM_CONFIG = 'FETCH_PLATFORM_CONFIG';
export const UPDATE_PLATFORM_CONFIG = 'UPDATE_PLATFORM_CONFIG';

// InviteUser
export const INVITE_NEW_USER = 'INVITE_NEW_USER';

// WORKSPACE
export const FETCH_USER_WORKSPACES = 'FETCH_USER_WORKSPACES';
export const FETCH_WORKSPACE = 'FETCH_WORKSPACE';
export const ADD_NEW_MEMBER = 'ADD_NEW_MEMBER';
export const UPDATE_WORKSPACE = 'UPDATE_WORKSPACE';
export const REMOVE_MEMBER = 'REMOVE_MEMBER';
export const ADD_WORKSPACE = 'ADD_WORKSPACE';
export const REMOVE_WORKSPACE = 'REMOVE_WORKSPACE';

// APIs
export const FETCH_APIS = 'FETCH_APIS';
export const DELETE_API = 'DELETE_API';
export const ADD_API = 'ADD_API';
export const EDIT_API = 'EDIT_API';

// APIs - ENDPOINTS
export const ADD_ENDPOINT = 'ADD_ENDPOINT';
export const EDIT_ENDPOINT = 'EDIT_ENDPOINT';
export const DELETE_ENDPOINT = 'DELETE_ENDPOINT';

// Types Library
export const FETCH_TYPES_LIBRARY = 'FETCH_TYPES_LIBRARY';
export const ADD_TYPE_LIBRARY = 'ADD_TYPE_LIBRARY';
export const DELETE_TYPE_LIBRARY = 'DELETE_TYPE_LIBRARY';
export const UPDATE_TYPE_LIBRARY = 'UPDATE_TYPE_LIBRARY';
export const RESOLVED_TYPES = 'RESOLVED_TYPES';
export const RESOLVED_TYPE = 'RESOLVED_TYPE';

/*  --------------MUTATIONS---------------  */
// Flow
export const MUTATE_FLOW_RECEIVED = 'MUTATE_FLOW_RECEIVED';
export const MUTATE_FLOWS_RECEIVED = 'MUTATE_FLOWS_RECEIVED';
export const MUTATE_FLOW_BY_NAME = 'MUTATE_FLOW_BY_NAME';
export const MUTATE_FLOW_CREATED = 'MUTATE_FLOW_CREATED';
export const MUTATE_SEARCH_FLOW = 'MUTATE_SEARCH_FLOW';
export const MUTATE_COPY_FLOW = 'MUTATE_COPY_FLOW';
export const MUTATE_SEARCH_BRICK = 'MUTATE_SEARCH_BRICK';
export const MUTATE_BRICKS_RECEIVED = 'MUTATE_BRICKS_RECEIVED';
export const MUTATE_BRICKS_ADDED = 'MUTATE_BRICKS_ADDED';
export const MUTATE_FILTER_BRICKS = 'MUTATE_FILTER_BRICKS';
export const MUTATE_CHANGE_RUN_STATE = 'MUTATE_CHANGE_RUN_STATE';
export const LOADING = 'LOADING';
export const MUTATE_FLOW_DELETED = 'MUTATE_FLOW_DELETED';
export const MUTATE_FLOW_IS_DIRTY = 'MUTATE_FLOW_IS_DIRTY';
export const MUTATE_GET_FLOW_STATE = 'MUTATE_GET_FLOW_STATE';
export const MUTATE_GET_FLOWS_STATE = 'MUTATE_GET_FLOWS_STATE';
export const MUTATE_FLOW_SORT_BY = 'MUTATE_FLOW_SORT_BY';
export const MUTATE_SORT_FLOWS = 'MUTATE_SORT_FLOWS';
export const MUTATE_EDIT_FLOW_NAME = 'MUTATE_EDIT_FLOW_NAME';
export const MUTATE_EDIT_FLOW_DESCRIPTION = 'MUTATE_EDIT_FLOW_DESCRIPTION';
export const MUTATE_PORT_RECEIVED = 'MUTATE_PORT_RECEIVED';
export const MUTATE_UPDATED_FLOW_IN_FLOW_LIST = 'MUTATE_UPDATED_FLOW_IN_FLOW_LIST';
export const MUATATE_ADD_PORT_MAPPING = 'MUATATE_ADD_PORT_MAPPING';
export const MUATATE_DELETE_PORT_MAPPING = 'MUATATE_DELETE_PORT_MAPPING';
export const MUTATE_ADD_BRICK_IN_FLOW = 'MUTATE_ADD_BRICK_IN_FLOW';
export const MUTATE_FLOWS_DELETED = 'MUTATE_FLOWS_DELETED';
export const MUTATE_DELETE_BRICK_IN_FLOW = 'MUTATE_DELETE_BRICK_IN_FLOW';
export const MUTATE_ADD_CONSTANT = 'MUTATE_ADD_CONSTANT';
export const MUTATE_EDIT_CONSTANT = 'MUTATE_EDIT_CONSTANT';
export const MUTATE_DELETE_CONSTANT = 'MUTATE_DELETE_CONSTANT';
export const MUTATE_ADD_CONNECTION = 'MUTATE_ADD_CONNECTION';
export const MUTATE_DELETE_CONNECTION = 'MUTATE_DELETE_CONNECTION';
export const MUTATE_ADD_CONNECTIONS = 'MUTATE_ADD_CONNECTIONS';
export const MUTATE_CREATE_NEW_BUFFER = 'MUTATE_CREATE_NEW_BUFFER';
export const MUTATE_ADD_TO_BUFFER = 'MUTATE_ADD_TO_BUFFER';
export const MUTATE_ADD_FIELD_TO_MAP = 'MUTATE_ADD_FIELD_TO_MAP';
export const MUTATE_REMOVE_FIELD_FROM_MAP = 'MUTATE_REMOVE_FIELD_FROM_MAP';
export const MUTATE_EDIT_FIELD_IN_MAP = 'MUTATE_EDIT_FIELD_IN_MAP';
export const MUTATE_DELETE_BUFFER = 'MUTATE_DELETE_BUFFER';
export const MUTATE_BUFFER_ELEMENT = 'MUTATE_BUFFER_ELEMENT';
export const MUTATE_UPDATE_BRICK = 'MUTATE_UPDATE_BRICK';
export const MUTATE_UPDATE_BRICK_LOADING = 'MUTATE_UPDATE_BRICK_LOADING';

// BRICKS
export const MUTATE_ADD_BRICK_FAILED = 'MUTATE_ADD_BRICK_FAILED';
export const MUTATE_ADD_BRICK_LOADING = 'MUTATE_ADD_BRICK_LOADING';
export const MUTATE_ADD_BRICK_SUCCESS = 'MUTATE_ADD_BRICK_SUCCESS';
export const MUTATE_DELETE_PACKAGE = 'MUTATE_DELETE_PACKAGE';

// PACKAGES
export const MUTATE_PACKAGES = 'MUTATE_PACKAGES';
export const MUTATE_PACKAGES_ADDED = 'MUTATE_PACKAGES_ADDED';

// USER
export const MUTATE_USER_LOGIN = 'MUTATE_USER_LOGIN';
export const MUTATE_USER_LOGOUT = 'MUTATE_USER_LOGOUT';
export const MUTATE_USER = 'MUTATE_USER';
export const MUTATE_USERS = 'MUTATE_USERS';
export const MUTATE_USERS_UPDATED = 'MUTATE_USERS_UPDATED';
export const MUTATE_USERS_ADMIN_RIGHT = 'MUTATE_USERS_ADMIN_RIGHT';
export const MUTATE_USERS_UPLOAD_RIGHT = 'MUTATE_USERS_UPLOAD_RIGHT';
export const MUTATE_FLOW_OWNER = 'MUTATE_FLOW_OWNER';
export const MUTATE_SIGNUP_REQUEST = 'MUTATE_SIGNUP_REQUEST';
export const MUTATE_FORGET_PASSWORD_REQUEST = 'MUTATE_FORGET_PASSWORD_REQUEST';
export const MUTATE_GRAVATARS = 'MUTATE_GRAVATARS';
export const MUTATE_REMOVE_USER_STATE = 'MUTATE_REMOVE_USER_STATE';

// ERROR
export const MUTATE_SET_ERROR = 'MUTATE_SET_ERROR';

// PLATFORM CONFIG
export const MUTATE_PLATFORM_CONFIG = 'MUTATE_PLATFORM_CONFIG';

// InviteUser
export const MUTATE_INVITED_USER = 'MUTATE_INVITED_USER';

// BRICK STORE
export const MUTATE_ALL_PACKAGES = 'MUTATE_ALL_PACKAGES';
export const MUTATE_BRICKS_OF_PACKAGE = 'MUTATE_BRICKS_OF_PACKAGE';

// WORKSPACE
export const MUTATE_USER_WORKSPACES = 'MUTATE_USER_WORKSPACES';
export const MUTATE_FETCH_WORKSPACE = 'MUTATE_FETCH_WORKSPACE';
export const MUTATE_NEW_MEMBER = 'MUTATE_NEW_MEMBER';
export const MUTATE_UPDATE_WORKSPACE = 'MUTATE_UPDATE_WORKSPACE';
export const MUTATE_REMOVE_MEMBER = 'MUTATE_REMOVE_MEMBER';
export const MUTATE_NEW_WORKSPACE = 'MUTATE_NEW_WORKSPACE';
export const MUTATE_REMOVE_WORKSPACE = 'MUTATE_REMOVE_WORKSPACE';

// APIs
export const MUTATE_APIS = 'MUTATE_APIS';
export const MUTATE_DELETED_API = 'MUTATE_DELETED_API';
export const MUTATE_ADD_API = 'MUTATE_ADD_API';
export const MUTATE_EDIT_API = 'MUTATE_EDIT_API';

// APIs - ENDPOINT
export const MUTATE_ADDED_ENDPOINT = 'MUTATE_ADDED_ENDPOINT';
export const MUTATE_EDITED_ENDPOINT = 'MUTATE_EDITED_ENDPOINT';
export const MUTATE_DELETED_ENDPOINT = 'MUTATE_DELETED_ENDPOINT';

// Types Library
export const MUTATE_FETCH_TYPES_LIBRARY = 'MUTATE_FETCH_TYPES_LIBRARY';
export const MUTATE_ADD_TYPE_LIBRARY = 'MUTATE_ADD_TYPE_LIBRARY';
export const MUTATE_DELETE_TYPE_LIBRARY = 'MUTATE_DELETE_TYPE_LIBRARY';
export const MUTATE_UPDATE_TYPE_LIBRARY = 'MUTATE_UPDATE_TYPE_LIBRARY';
export const MUTATE_RESOLVED_TYPES = 'MUTATE_RESOLVED_TYPES';
export const MUTATE_RESOLVED_TYPE = 'MUTATE_RESOLVED_TYPE';
