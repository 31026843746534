
import 'typeface-roboto';
import { Vue, Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import Navigation from '@/components/shared/Navigation.vue';
import Toolbar from '@/components/shared/Toolbar.vue';
import Toast from '@/components/shared/Toast.vue';
import * as types from '@/store/types';

Vue.component('Navigation', Navigation);
Vue.component('Toolbar', Toolbar);
Vue.component('Toast', Toast);

const namespacePlatformConfig: string = 'platformConfig';

@Component
export default class App extends Vue {
  @Action(types.FETCH_PLATFORM_CONFIG, { namespace: namespacePlatformConfig })
  fetchPlatformConfig: any;

  async created() {
    await this.fetchPlatformConfig();

    const isThemeDark: boolean = JSON.parse(localStorage.getItem('darkTheme') || 'false');

    this.$vuetify.theme.dark = isThemeDark;
  }
}
