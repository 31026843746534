
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/types';
import { Gravatar } from '@/store/user/models';

const namespace: string = 'user';

@Component
export default class Avatar extends Vue {
  // Props
  @Prop({ default: { email: '' } }) getUser: any;

  @Prop() size: number | undefined;

  @Prop() fontSizeClass!: string;

  // Actions
  @Action(types.FETCH_GRAVATAR, { namespace }) fetchGravatar: any;

  // Getters
  @Getter(types.GET_GRAVATARS, { namespace }) getGravatars: any;

  // Data
  private colorList: string[] = [
    'red',
    'pink',
    'purple',
    'indigo',
    'blue',
    'cyan',
    'teal',
    'green',
    'lime',
    'amber',
    'orange',
    'brown',
    'blue-grey',
    'light-blue',
  ];

  // Watchers
  @Watch('getUser', { deep: true })
  onGetUser() {
    this.setGravatar();
  }

  // Vue Life Cycle Hooks

  async created() {
    await this.setGravatar();
  }

  // Computed
  get fontSize(): string {
    if (!this.fontSizeClass) {
      return 'display-2';
    }

    return this.fontSizeClass;
  }

  get getGravatar(): string {
    if (!this.getUser && !this.getUser.email) {
      return '';
    }

    const gravatar = this.findGravatar(this.getUser.email);

    if (gravatar) {
      return gravatar.url;
    }

    return '';
  }

  get avatarSize(): number {
    if (!this.size) {
      // default size
      return 48;
    }
    return this.size;
  }

  /**
   * If the avatar is not exist show the first two character
   * from the email address
   */
  get initials(): string {
    let intls = '';
    const { email } = this.getUser;

    if (email && email.length > 0) {
      intls = email.substring(0, 2).toUpperCase();
    } else {
      intls = '';
    }

    return intls;
  }

  /**
   * Gets a background color for the
   * avatar when showing initials
   */
  get getBackgroundColor(): string {
    let colorIndex = 0;
    if (this.getUser.email) {
      const { email } = this.getUser;
      const len = this.colorList.length;
      const chars = email
        .substring(0, 2)
        .toUpperCase()
        .charCodeAt();
      colorIndex = (chars * 26) % len;
    }
    return this.colorList[colorIndex];
  }

  // Methods

  /**
   * Checks in the state if the gravatar for the
   * user already exists and returns. If not, then
   * fetches a fresh copy from Gravatar and
   * saves it in the state
   */
  async setGravatar() {
    const { email } = this.getUser;

    if (!email) return;

    const gravatar = this.findGravatar(email);

    if (!gravatar) {
      await this.fetchGravatar(email).catch(() => {});
    }
  }

  /**
   * Find the gravatar from the state
   */
  findGravatar(email: string) {
    const findGravatar = this.getGravatars.find((g: Gravatar) => g.email === email);
    return findGravatar;
  }
}
