
import { Action, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as types from '@/store/types';
import {
  Flow,
} from '@/store/flow/models';

const namespace: string = 'flow';
@Component
export default class CopyFlow extends Vue {
  @Action(types.COPY_FLOW, { namespace }) copyFlow: any;

  @Getter(types.GET_FLOWS, { namespace }) getFlows: any;

  @Prop() flowID!: string;

  @Prop({ default: false }) dialog!: boolean;

  private newName: string = '';

  private put() {
    this.newName = this.newName.trim();
    const flow = this.getFlows.find((f: Flow) => f.id === this.flowID);

    if (!this.newName || !this.flowID || !flow) {
      return;
    }

    this.copyFlow({
      oldFlow: this.flowID,
      newFlow: this.newName,
      workspaceId: flow.workspaceId,
    });
    this.newName = '';
    this.$emit('close');
  }
}
