/*
 Copyright 2019-present wobe-systems GmbH

 Licensed under the Apache License, Version 2.0 (the 'License');
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an 'AS IS' BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
import { MutationTree } from 'vuex';
import * as types from '@/store/types';
import { BricksState, InstalledBrick, Package } from './models';

export const mutations: MutationTree<BricksState> = {
  [types.LOADING](state, loading: BricksState['loading']) {
    state.loading = loading;
  },

  [types.MUTATE_BRICKS_RECEIVED](state, payload: BricksState['bricks']) {
    state.bricks = payload;
    state.selectedBricks = payload;
  },

  [types.MUTATE_PACKAGES](state, payload: BricksState['packages']) {
    state.packages = payload;
  },

  [types.MUTATE_PACKAGES_ADDED](state, payload: Package) {
    const indexFound = state.packages.findIndex((pkg) => pkg.id === payload.id);

    if (indexFound !== -1) {
      state.packages.splice(indexFound, 1, payload);
    } else {
      state.packages.push(payload);
    }
  },

  [types.MUTATE_BRICKS_ADDED](state, payload: InstalledBrick[]) {
    let { bricks } = state;
    let { selectedBricks } = state;

    // filter out old bricks
    bricks = bricks.filter(
      (brick: InstalledBrick) => !payload.map((br) => br.id).includes(brick.id),
    );
    selectedBricks = selectedBricks.filter(
      (brick: InstalledBrick) => !payload.map((br) => br.id).includes(brick.id),
    );

    // add new ones from payload to store
    state.bricks = bricks.concat(payload);
    state.selectedBricks = selectedBricks.concat(payload);
  },

  [types.MUTATE_ADD_BRICK_LOADING](state, payload: BricksState['addLoading']) {
    state.addLoading = payload;
  },

  [types.MUTATE_ADD_BRICK_FAILED](state, payload: any) {
    state.addErrMsg = payload;
  },

  [types.MUTATE_ADD_BRICK_SUCCESS](state, payload: BricksState['addSuccess']) {
    state.addSuccess = payload;
  },

  [types.MUTATE_SEARCH_BRICK](state: any, payload: any) {
    const { bricks } = state;
    let { searchParam, filterParam } = payload;

    /* clearable icon reset the text filed by adding the null.
       we can not use toLowerCase for null and cause console error,
       that is why we need to replace it with ''
     */
    if (searchParam === null) {
      searchParam = '';
    }
    if (filterParam === null) {
      filterParam = '';
    }

    if (filterParam && filterParam.length > 0) {
      state.selectedBricks = bricks.filter(
        // eslint-disable-next-line max-len
        (brick: { name: string; family: string }) => brick.name.toLowerCase().includes(searchParam.toLowerCase())
          && filterParam === brick.family,
      );
    } else {
      state.selectedBricks = bricks.filter(
        (brick: { name: string }) => brick.name.toLowerCase().includes(searchParam.toLowerCase()),
      );
    }
  },

  [types.MUTATE_FILTER_BRICKS](state: any, payload: any) {
    let { searchParam, filterParam } = payload;

    /* clearable icon reset the text filed by adding the null.
       we can not use toLowerCase for null and cause console error,
       that is why we need to replace it with ''
     */
    if (searchParam === null) {
      searchParam = '';
    }
    if (filterParam === null) {
      filterParam = '';
    }

    let bricks = null;
    if (!filterParam) {
      bricks = state.bricks;
    } else {
      // eslint-disable-next-line max-len
      bricks = state.bricks.filter((brick: any) => brick.family === filterParam);
    }

    if (searchParam && searchParam.length > 0) {
      bricks = bricks.filter(
        (brick: { name: string }) => brick.name.toLowerCase().includes(searchParam.toLowerCase()),
      );
    }
    state.selectedBricks = bricks;
  },

  [types.MUTATE_DELETE_PACKAGE](state, packageName: string) {
    state.packages = state.packages.filter((p) => p.id !== packageName);
    state.bricks = state.bricks.filter((b) => b.packageId !== packageName);
    state.selectedBricks = state.selectedBricks.filter((b) => b.packageId !== packageName);
  },
};
