
import { Action, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '@/store/user/models';
import Avatar from '@/components/user/Avatar.vue';
import * as types from '@/store/types';

const namespace: string = 'user';

@Component({
  components: {
    Avatar,
  },
})
export default class UserMenu extends Vue {
  @Prop() color: string | undefined;

  @Action(types.LOGOUT, { namespace }) Logout: any;

  @Getter(types.GET_LOGIN_STATUS, { namespace }) loginStatus: any;

  @Getter(types.GET_USER, { namespace }) getUser: any;

  private menuItems: any[] = [
    {
      icon: 'mdi-logout-variant',
      name: 'logout',
      method: this.logout,
    },
  ];

  // eslint-disable-next-line class-methods-use-this
  get changeTheme(): boolean {
    return JSON.parse(localStorage.getItem('darkTheme') || 'false');
  }

  set changeTheme(value: boolean) {
    localStorage.setItem('darkTheme', value.toString());
    this.$vuetify.theme.dark = value;
  }

  async logout() {
    const user: User = this.getUser;

    await this.Logout();
    if (!this.loginStatus) {
      this.$router.push('/login');
    }
  }

  async routeToUserAccount() {
    if (this.$route.name !== 'userAccount') {
      this.$router.push({ name: 'userAccount' });
    }
  }
}
