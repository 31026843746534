import { SchemaType } from '@/scripts/shareModels/schema';
import { AllTypes } from '@/scripts/shareModels/types';
import { Port } from '@/store/bricks/models';
import { BrickInFlow, Connection } from '@/store/flow/models';
import Vue from 'vue';

interface CustomSchemaType {
  parent?: SchemaType;
  child: SchemaType;
  indexOfChild: number;
}

export const isObject = (object: any) => object != null && typeof object === 'object';

export const deepEqual = (object1: any, object2: any) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
};

/**
 * Finds out the item from the PopulatedJSON given
 * a mapping rule
 *
 * @param populatedJSON
 * @param mappingRule The mapping rule that will be compared to
 * the parent's of each object inside the populated JSON
 * @param nestingKey The key which allows to go deep in nesting through recursion
 */
export const findArrayInNestedArray = (
  populatedJSON: any[],
  mappingRule: any,
  nestingKey: any,
): any => populatedJSON.reduce((a, item) => {
  if (a) return a;
  if (item.parents.concat(new Array(item.name)).join() === mappingRule.join()) {
    return item;
  }

  if (item[nestingKey]) {
    return findArrayInNestedArray(item[nestingKey], mappingRule, nestingKey);
  }

  return null;
}, null);

/**
 * Finds a schema with the help of its ID within
 * a populated JSON of a brick port
 *
 * @param nestedObject The schema type to traverse
 * @param idToFind The id to find in populated JSON
 *
 * @returns The following:
 * - The parent of the item to find
 * - The item itself
 * - The index at which the item exists
 * in the parent's elements array
 */
export const findKeyInNestedObject = (
  nestedObject: SchemaType,
  idToFind: string,
  parent?: SchemaType,
  index: number = -1,
): CustomSchemaType | undefined => {
  if (nestedObject.id === idToFind) {
    return {
      parent,
      child: nestedObject,
      indexOfChild: index,
    };
  }

  let result;
  if (nestedObject.elements && nestedObject.elements.length > 0) {
    for (let i = 0; i < nestedObject.elements.length; i += 1) {
      result = findKeyInNestedObject(nestedObject.elements[i], idToFind, nestedObject, i);

      if (result) break;
    }
  }

  return result;
};

/**
 * Traverses through all connections inside a given array of
 * connections and removes the mapping rule where
 * the connected element of @param port is not available in port's elements
 *
 * @param connections List of connections inside a flow
 * @param port which field is being added/ edited
 */
export const removeMappingIfConnectedFieldsAreNotAvailable = (
  sourceOrTargetPort: any,
  connections: Connection[],
) => {
  const populatedPort: SchemaType[] = [];
  // eslint-disable-next-line
  const isSourcePort: boolean = false ? sourceOrTargetPort.type === 'output' : true;
  let found: boolean = false;
  populatedPort.push(sourceOrTargetPort.populatedJSON);

  connections.forEach((connection) => {
    connection.mapping.forEach((map, index, mapping) => {
      if (map.usedElements && map.usedElements.length > 0) {
        // check the id is available or not in port's elements
        const checkId = map.usedElements[0] ? isSourcePort : map.usedElements[1];

        populatedPort.forEach((port: any) => {
          if (port.id === checkId) {
            found = true;
          }

          if (port.elements && port.elements.length > 0) {
            port.elements.forEach((element: any) => {
              if (element.id === checkId) {
                found = true;
              }
            });
          }
        });
      }
      if (!found) {
        mapping.splice(index, 1);
      }
    });
  });
};
/**
 * Traverses through all connections inside a given array of
 * connections and removes the mapping rule where a certain
 * schema type @param itemToFind is being used
 *
 * @param connections List of connections inside a flow
 * @param itemToFind The item that needs to be found in a mapping rule
 */
export const removeMappingForField = (connections: Connection[], itemToFind: SchemaType) => {
  // traverse connections to find if item was used in any mapping
  connections.forEach((connection) => {
    connection.mapping.forEach((map, index, mapping) => {
      // if item is used in current mapping, remove mapping
      if (itemToFind.id && map.usedElements.includes(itemToFind.id)) {
        mapping.splice(index, 1);
      }
    });
  });
};

/**
 * Edits or deletes a schema from the element array
 * of an existing map in the port of a brick
 *
 * @param bricks All the bricks inside a flow
 * @param brick The brick that whose port needs to be worked on
 * @param port The port whose schema needs to be modified
 * @param itemToFind The schema that needs to be found
 * @param mode The mode of operation i.e. delete or edit
 */
export const editOrDeleteSchemaFromElements = (
  bricks: BrickInFlow[],
  brick: BrickInFlow,
  port: any,
  itemToFind: SchemaType,
  mode: string = 'delete',
) => {
  // find brick in whose port the schema is in
  const brickToModify = bricks.find((b) => b.instanceId === brick.instanceId);

  // find port where the schema is
  let portToModify: Port | undefined;
  if (port.type === 'input') {
    portToModify = brickToModify?.inputPorts.find((p) => p.name === port.name);
  } else {
    portToModify = brickToModify?.outputPorts.find((p) => p.name === port.name);
  }

  // continue if port was found
  if (portToModify) {
    const { populatedJSON } = portToModify;

    if (itemToFind.id) {
      // find parent of schema
      const result = findKeyInNestedObject(populatedJSON, itemToFind.id);

      // in case changind the parent element
      if (result && result.indexOfChild === -1 && mode === 'edit') {
        portToModify.populatedJSON = itemToFind;
      }

      if (result && result.parent && result.indexOfChild >= 0) {
        const { parent, indexOfChild } = result;

        if (mode === 'delete') {
          // remove schema from elements of the parent
          if (parent && parent.elements) parent.elements.splice(indexOfChild, 1);
        } else if (parent && parent.elements) parent.elements.splice(indexOfChild, 1, itemToFind);
      }
    }
  }
};

/**
 * Generates a chain of parents for the schema recursively.
 *
 * The first schema given will be treated as root
 * and the name will be used as the start of the parent
 * chain. This chain will be added to with the name of
 * elements in the schema until the deepest nesting of
 * the object
 *
 * @param schema The schema to which a parent chain needs to be added to
 * @param parentChain The parent chain that needs to be added in @param schema.parents
 * @param shouldUseId Sometimes in the parent chain it is required to use IDs of
 * the types instead of their names @default false
 */
export const generateParentChainForSchema = (
  schema: SchemaType,
  parentChain: string[],
  shouldUseId: boolean = false,
) => {
  // empty out the parent of the schema
  // to form a new chain
  Object.defineProperty(schema, 'parents', {
    value: [],
    writable: true,
    configurable: true,
    enumerable: true,
  });

  // append the chain
  if (parentChain && schema.parents) {
    schema.parents.push(...parentChain);
  }

  if (schema.elements && schema.elements.length > 0) {
    // append new parent to the chain
    if (shouldUseId) {
      parentChain.push(schema.id ? schema.id : '');
    } else {
      parentChain.push(schema.name);
    }

    schema.elements.forEach((element) => {
      // rinse and repeat
      generateParentChainForSchema(element, parentChain);
    });
  }

  return schema;
};

/**
 * Builds a JSON object for displaying the request
 * and response bodies in the UI
 *
 * @param object Object whose information needs to be
 * extracted and put in @param json for display
 * @param json The JSON that needs to be displayed on UI
 * @returns A JSON with the following format:
 *
 * {
 *     "key": "type"
 *     "key": {
 *          "key": "type"
 *      }
 * }
 */
export const buildJSONForDisplay = (object: SchemaType, json?: any) => {
  if (object.type !== 'object' && object.type !== 'list') {
    Vue.set(json, object.name, object.type);
  }

  // form an object with {}
  if (object.type === 'object') {
    if (object.elements) {
      Vue.set(json, object.name, {});

      object.elements.forEach((element) => buildJSONForDisplay(element, json[object.name]));
    }
  }

  // form a list with []
  if (object.type === 'list') {
    Vue.set(json, object.name, []);

    // a list can have multiple elements so
    // we check for that and build JSON for it
    if (object.elements) {
      object.elements.forEach((element) => buildJSONForDisplay(element, json[object.name]));
    } else {
      json.push(object.list_type);
    }
  }

  return json;
};

export const isReservedType = (type: string) => AllTypes.includes(type);
