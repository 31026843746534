/*
 Copyright 2019-present wobe-systems GmbH

 Licensed under the Apache License, Version 2.0 (the 'License');
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an 'AS IS' BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
import { ActionTree } from 'vuex';
import * as types from '@/store/types';
import { handleErrorMessage, handleInfoMessage } from '@/store/handleCatchError';
import { logging } from '@/scripts/debugger';
import { OK, SERVICES } from '@/middlewares/httpClient';
import { BricksState, InstalledBrick, Package } from './models';
import { RootState } from '../models';
import {
  GetBricks, PostBrick, DeletePackage, GetPackages, InstallPackage,
} from './apis';

const log = (message: string) => {
  logging('VUEX/brick/actions', message);
};

const service : string = SERVICES.get('packagemanager');

export const actions: ActionTree<BricksState, RootState> = {
  async [types.FETCH_BRICKS]({ commit, dispatch }, id:string): Promise<any> {
    commit(types.LOADING, true);
    return new Promise((resolve, reject) => {
      GetBricks(id)
        .then(({ data }) => {
          const brickObject: BricksState['bricks'] = data;
          commit(types.MUTATE_BRICKS_RECEIVED, brickObject);
          commit(types.LOADING, false);
          resolve(data);
        })
        .catch((err) => {
          commit(types.LOADING, false);
          handleErrorMessage(err, dispatch, service);
          reject(err);
        });
    });
  },

  async [types.FETCH_PACKAGES]({ commit, dispatch }, id:string): Promise<any> {
    commit(types.LOADING, true);
    return new Promise((resolve, reject) => {
      GetPackages(id)
        .then(({ data }) => {
          const packages: BricksState['packages'] = data;

          commit(types.MUTATE_PACKAGES, packages);
          commit(types.LOADING, false);
          resolve(data);
        })
        .catch((err) => {
          commit(types.LOADING, false);
          handleErrorMessage(err, dispatch, service);
          reject(err);
        });
    });
  },

  [types.SEARCH_BRICK]: ({ commit }, searchterm) => {
    commit(types.MUTATE_SEARCH_BRICK, searchterm);
  },
  [types.FILTER_BRICKS]: ({ commit }, filterterm) => {
    commit(types.MUTATE_FILTER_BRICKS, filterterm);
  },

  async [types.ADD_BRICK]({ commit, dispatch }, { userId, file }:{userId:string, file:any}):
    Promise<any> {
    log('ADD_BRICK is called');
    commit(types.MUTATE_ADD_BRICK_LOADING, true);
    await PostBrick(userId, file)
      .then(({ status, data }) => {
        if (status === OK) {
          const brickObject: InstalledBrick[] = data.bricks;
          const packageObject: Package = data.package;

          commit(types.MUTATE_BRICKS_ADDED, brickObject);
          commit(types.MUTATE_PACKAGES_ADDED, packageObject);

          handleInfoMessage('brick_created', dispatch);

          commit(types.MUTATE_ADD_BRICK_SUCCESS, true);
          commit(types.MUTATE_ADD_BRICK_LOADING, false);
          commit(types.MUTATE_ADD_BRICK_FAILED, '');
        }
      }).catch((e) => {
        let errorMessage: string = '';
        if (e.response) {
          errorMessage = e.response.data;
        } else {
          errorMessage = e.message;
        }
        commit(types.MUTATE_ADD_BRICK_FAILED, errorMessage);
        commit(types.MUTATE_ADD_BRICK_LOADING, false);
      });
  },

  async [types.INSTALL_PACKAGE]({ commit, dispatch }, { userId, id }:{userId:string, id:string}):
    Promise<any> {
    log('INSTALL_PACKAGE is called');

    commit(types.MUTATE_ADD_BRICK_LOADING, true);

    await InstallPackage(userId, id)
      .then(({ status }) => {
        if (status === OK) {
          handleInfoMessage('brick_created', dispatch);
          commit(types.MUTATE_ADD_BRICK_LOADING, false);
        }
      }).catch((e) => {
        let errorMessage: string = '';
        if (e.response) {
          errorMessage = e.response.data;
        } else {
          errorMessage = e.message;
        }
        commit(types.MUTATE_ADD_BRICK_FAILED, errorMessage);
        commit(types.MUTATE_ADD_BRICK_LOADING, false);
      });
  },
  async [types.ADD_BRICK_CLEAR_STATE]({ commit }, file: any): Promise<any> {
    commit(types.MUTATE_ADD_BRICK_SUCCESS, false);
    commit(types.MUTATE_ADD_BRICK_LOADING, false);
    commit(types.MUTATE_ADD_BRICK_FAILED, '');
  },

  async [types.DELETE_PACKAGE]({ commit, dispatch }, { userId, packageToDelete }): Promise<any> {
    return new Promise((resolve, reject) => {
      DeletePackage(userId, packageToDelete)
        .then(({ status }) => {
          if (status === OK) {
            handleInfoMessage('package_deleted', dispatch);
            commit(types.MUTATE_DELETE_PACKAGE, packageToDelete);
            resolve(status);
          }
        })
        .catch((err) => {
          handleErrorMessage(err, dispatch, service);
          reject(err);
        });
    });
  },
};
