/*
 Copyright 2019-present wobe-systems GmbH

 Licensed under the Apache License, Version 2.0 (the 'License');
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an 'AS IS' BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/

import { Port, InstalledBrick } from '@/store/bricks/models';
import { Constant, SchemaType } from '@/scripts/shareModels/schema';

interface port {
  id : string;
  typeName: string;
  schema: string;
}

export interface Ports {
  source: port;
  target: port;
}
export interface MappingRule{
  type: string
  bufferId?: string
  sourceFields: string[]
  targetFields: string[]
  constant: Constant
}
export interface MappedElement {
  rule : MappingRule
  usedElements : string[]
}

export interface IBuffer {
  id: string;
  value: SchemaType;
  source: string[];
}

export interface BufferElement {
  [key: string]: IBuffer
}
interface ConnectedPort {
  id : string;
  typeName: string;
  schema: string;
}

interface ConnectionEndpoint{
  brick:string;
  port: ConnectedPort;
}

export interface Connection {
  id: string
  workspaceId: string;
  source: ConnectionEndpoint;
  target: ConnectionEndpoint;
  mapping: MappedElement[];
  buffer: BufferElement;
}

export interface NewPorts {
  source: string;
  target: string;
}

export interface BrickInFlow extends InstalledBrick{

}

export interface Configuration {
  id: string;
  name: string;
  bricks: BrickInFlow[];
  connectionConfigId: string;
  connections: [];
  constants: Constant[];
  mappedBuffers: any;
}

export interface FlowRunConfig {
  id: string;
  name: string;
  bricks: BrickInFlow[];
  connections: Connection[];
}

export interface ControlPeer {
  address: string;
  state: number;
}
export interface Flow {
  id: string;
  name: string;
  owner: string;
  config: Configuration;
  running: boolean;
  peer: ControlPeer[];
  previewimage: string;
  description: string;
  isDirty: boolean;
  workspaceId: string;
}
export interface FlowsState {
  flows: Flow[];
  selectedFlows: Flow[];
  flow: Flow;
  bricks: BrickInFlow[];
  loading: boolean;
  sortFlowsBy: string;
  port: Port,
  connections: Connection[];
  connection: Connection;
  connectionSource?: SchemaType;
  updateBrickLoading: boolean;
}

export interface NewParameter {
  name: string;
  value: string;
  type: string;
}

export const EmptyConnection : Connection = {
  id: '',
  source: {
    brick: '',
    port: {
      id: '',
      typeName: '',
      schema: '',
    },
  },
  target: {
    brick: '',
    port: {
      id: '',
      typeName: '',
      schema: '',
    },
  },
  mapping: [],
  buffer: {},
  workspaceId: '',
};

export const GetFlowRunConfigFromConfig = (config: Configuration, connections: Connection[]) => {
  const gf : FlowRunConfig = {
    id: config.id,
    name: config.name,
    bricks: config.bricks,
    connections,
  };
  return gf;
};
