/*
 Copyright 2019-present wobe-systems GmbH

 Licensed under the Apache License, Version 2.0 (the 'License');
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an 'AS IS' BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
import { ActionTree } from 'vuex';
import * as types from '@/store/types';
import { handleErrorMessage, handleInfoMessage } from '@/store/handleCatchError';
import { logging } from '@/scripts/debugger';
import { OK, SERVICES } from '@/middlewares/httpClient';
import {
  Connection,
  GetFlowRunConfigFromConfig,
  FlowsState,
  Flow,
  MappedElement,
  BrickInFlow,
  IBuffer,
} from '@/store/flow/models';
import { InstalledBrick, Port } from '@/store/bricks/models';
import { Constant, SchemaType } from '@/scripts/shareModels/schema';
import {
  resetConstantValidation,
  validateConstant,
} from '@/scripts/shareModels/mapping_validation';
import i18n from '@/middlewares/i18n';
import {
  GetFlows,
  GetFlow,
  DeleteFlow,
  PostFlow,
  PutFlow,
  CopyFlow,
  EditFlowName,
  EditFlowDescription,
  StartFlow,
  StopFlow,
  GetFlowState,
  GetFlowsState,
  CreatePortJSON,
  CreateConnection,
  GetConnections,
  DeleteConnection,
  UpdateConnection,
  AddBrick,
  UpdateBrick,
  DeleteBrick,
  ConfigureAPIBrick,
  ExportFlow,
  ImportFlow,
} from './apis';

import { RootState } from '../models';

import { FlowOwner } from '../user/models';

const log = (message: string) => {
  logging('VUEX/flow/actions', message);
};

const service: string = SERVICES.get('flowmanager');

export const actions: ActionTree<FlowsState, RootState> = {
  async [types.CHANGE_RUN_STATE]({ commit, dispatch, state }: any, parameters: any): Promise<any> {
    log(`CREATE_FLOW is called, name is ${parameters.id}`);
    const { id, running } = parameters;
    let { config } = state.flow;
    const { connections } = state;

    // If the play button was clicked from the flow list
    // the flow needs to be found from the array
    if (!config || !config.id) {
      const { flows } = state;
      const flow: Flow = flows.find((f: Flow) => f.id === id);
      config = flow.config;
    }

    const flowRunConfig = GetFlowRunConfigFromConfig(config, connections);

    if (running) {
      await StartFlow(id, flowRunConfig)
        .then(({ status, data }: any) => {
          if (status === OK) {
            const runstate: boolean = data;
            commit(types.MUTATE_CHANGE_RUN_STATE, { id, running: runstate });
            handleInfoMessage('flow_state_changed', dispatch);
          }
        })
        .catch((e) => handleErrorMessage(e, dispatch, service));
    } else {
      await StopFlow(id, flowRunConfig)
        .then(({ status, data }: any) => {
          if (status === OK) {
            const runstate: boolean = data;
            commit(types.MUTATE_CHANGE_RUN_STATE, { id, running: runstate });
            handleInfoMessage('flow_state_changed', dispatch);
          }
        })
        .catch((e) => handleErrorMessage(e, dispatch, service));
    }
  },

  async [types.CREATE_FLOW]({ commit, dispatch }, { name, workspaceId }): Promise<any> {
    log(`CREATE_FLOW is called, name is ${name}`);
    let flowObject: any = {};
    await PostFlow(name, workspaceId)
      .then(({ status, data }) => {
        if (status === OK) {
          flowObject = data;
          commit(types.MUTATE_FLOW_CREATED, flowObject);
          handleInfoMessage('flow_created', dispatch);
        }
      })
      .catch((e) => handleErrorMessage(e, dispatch, service));
    const user = await dispatch(
      `user/${types.FETCH_USER}`,
      { id: flowObject.owner },
      { root: true },
    );
    commit(`user/${types.MUTATE_FLOW_OWNER}`, user, { root: true });
  },

  async [types.CREATE_PORT_JSON]({ commit, dispatch }, port: Port): Promise<any> {
    log(`CREATE_PORT is called for port ${port.typeName}`);

    await CreatePortJSON(port)
      .then(({ status, data }) => {
        if (status === OK) {
          const portObject: FlowsState['port'] = data;
          commit(types.MUTATE_PORT_RECEIVED, portObject);
          handleInfoMessage('port_schema_created', dispatch);
        }
      })
      .catch((e) => handleErrorMessage(e, dispatch, service));
  },

  async [types.COPY_FLOW]({ commit, dispatch }, { oldFlow, newFlow, workspaceId }): Promise<any> {
    log(`COPY_FLOW is called, oldFlow is ${oldFlow}, newFlow is ${newFlow}`);
    let flowObject: any = {};
    await CopyFlow(oldFlow, newFlow, workspaceId)
      .then(({ status, data }) => {
        if (status === OK) {
          flowObject = data;
          commit(types.MUTATE_COPY_FLOW, flowObject);
          handleInfoMessage('flow_copied', dispatch);
        }
      })
      .catch((e) => handleErrorMessage(e, dispatch, service));
    const user = await dispatch(
      `user/${types.FETCH_USER}`,
      { id: flowObject.owner },
      { root: true },
    );
    commit(`user/${types.MUTATE_FLOW_OWNER}`, user, { root: true });
  },

  async [types.DELETE_FLOW]({ commit, dispatch }, id: string): Promise<any> {
    log(`DELETE_FLOW is called, id is ${id}`);

    await DeleteFlow(id)
      .then(({ status }) => {
        if (status === OK) {
          commit(types.MUTATE_FLOW_DELETED, id);
          handleInfoMessage('flow_deleted', dispatch);
        }
      })
      .catch((e) => handleErrorMessage(e, dispatch, service));
  },

  async [types.CREATE_CONNECTION]({ commit, dispatch }, { configID, connection }): Promise<any> {
    if (!connection || !configID) return;

    await CreateConnection(configID, connection)
      .then(({ status, data }) => {
        if (status === OK) {
          const newConnection = data;
          commit(types.MUTATE_ADD_CONNECTION, newConnection);
        }
      })
      .catch((e) => handleErrorMessage(e, dispatch, service));
  },
  async [types.UPDATE_CONNECTION](
    { commit, dispatch },
    { configID, connection }: { configID: string; connection: Connection },
  ): Promise<any> {
    if (!connection || !configID) return;

    await UpdateConnection(configID, connection)
      .then(({ status, data }) => {
        if (status === OK) {
          const newConnections: Connection[] = data;
          commit(types.MUTATE_ADD_CONNECTIONS, newConnections);
        }
      })
      .catch((e) => handleErrorMessage(e, dispatch, service));
  },

  async [types.DELETE_CONNECTION]({ commit, dispatch }, { configID, connectionID }): Promise<any> {
    // if (!connectionID || !configID) return;

    return new Promise((resolve, reject) => {
      if (!connectionID || !configID) {
        reject(new Error('connection ID and config ID needed to delete connection'));
      }

      DeleteConnection(configID, connectionID)
        .then(({ status, data }) => {
          if (status === OK) {
            const newConnections: Connection[] = data;
            commit(types.MUTATE_ADD_CONNECTIONS, newConnections);
            handleInfoMessage('connection_deleted', dispatch);

            resolve(true);
          }
        })
        .catch((e) => {
          handleErrorMessage(e, dispatch, service);
          reject(e);
        });
    });
  },
  async [types.FETCH_CONNECTIONS]({ commit, dispatch }, configID): Promise<any> {
    commit(types.LOADING, true);
    await GetConnections(configID)
      .then(({ status, data }) => {
        if (status === OK) {
          const newConnections: Connection[] = data;
          commit(types.MUTATE_ADD_CONNECTIONS, newConnections);
          commit(types.LOADING, false);
        }
      })
      .catch((e) => {
        commit(types.LOADING, false);
        handleErrorMessage(e, dispatch, service);
      });
  },

  async [types.FETCH_FLOWS]({ commit, dispatch }, id): Promise<any> {
    log('FETCH_FLOWS is called');
    if (!id) {
      return;
    }
    commit(types.LOADING, true);
    let flows: FlowsState['flows'] = [];
    let flowState: FlowsState['flows'] = [];

    // Get all the flows
    await GetFlows(id)
      .then(({ data }) => {
        flows = data || [];
      })
      .catch((err) => {
        handleErrorMessage(err, dispatch, service);
      });

    // Get running state for all flows
    await GetFlowsState()
      .then(({ data }) => {
        flowState = data || [];
      })
      .catch((err) => handleErrorMessage(err, dispatch, service));

    // extract all unique owners from flows
    const uniqueOwners = Array.from(new Set(flows.map((flow) => flow.owner)));
    await Promise.all(
      uniqueOwners.map((owner) => dispatch(`user/${types.FETCH_USER}`, { id: owner }, { root: true })),
    ).then((flowOwners: FlowOwner[]) => {
      // commit all ids received
      flowOwners.map((flowOwner) => commit(`user/${types.MUTATE_FLOW_OWNER}`, flowOwner, { root: true }));
    });

    commit(types.MUTATE_FLOWS_RECEIVED, flows);
    commit(types.MUTATE_GET_FLOWS_STATE, flowState);

    commit(types.LOADING, false);
  },

  async [types.FETCH_FLOW]({ commit, dispatch }, { id, shouldLoad = true }): Promise<any> {
    log('FETCH_FLOW is called');
    if (shouldLoad) {
      commit(types.LOADING, true);
    }
    const gridManager = 'gridManager';
    const flowManager = 'flowManager';
    const networkError = 'Network Error';
    const gridNetworkError = 'Connection to the Grid Manager could not be established.';
    const flowNetworkError = 'Connection to the Flow Manager could not be established.';

    let flowObject: FlowsState['flow'];
    let flow: Flow;

    const getFlow = new Promise((resolve, reject) => {
      GetFlow(id)
        .then(({ data }) => {
          // set data for commit
          flowObject = data;
          resolve(data);
        })
        .catch((err) => {
          const error = { name: flowManager, err };
          reject(error);
        });
    });

    const getFlowState = new Promise((resolve, reject) => {
      GetFlowState(id)
        .then(({ data }) => {
          // set data for commit
          flow = data;

          resolve(data);
        })
        .catch((err) => {
          const error = { name: gridManager, err };
          reject(error);
        });
    });

    return new Promise((resolve, reject) => {
      Promise.all([getFlow, getFlowState])
        .then((data) => {
          commit(types.MUTATE_FLOW_RECEIVED, flowObject);
          commit(types.MUTATE_GET_FLOW_STATE, flow.running);
          commit(types.MUTATE_ADD_CONNECTIONS, flowObject.config.connections);

          commit(types.LOADING, false);
          resolve(data);
        })
        .catch((error) => {
          commit(types.LOADING, false);
          const { name, err } = error;

          if (name === gridManager && err.message === networkError) {
            err.message = gridNetworkError;
          } else if (name === flowManager && err.message === networkError) {
            err.message = flowNetworkError;
          }
          reject(err);
          handleErrorMessage(err, dispatch, service);
        });
    });
  },

  [types.SET_SELECTED_FLOW]: ({ commit }, flow) => {
    log('SET_SELECTED_FLOW is called');

    commit(types.MUTATE_FLOW_RECEIVED, flow);
  },

  [types.SEARCH_FLOW]: ({ commit }, name) => {
    log('SEARCH_FLOW is called');

    commit(types.MUTATE_SEARCH_FLOW, name);
  },

  [types.FETCH_BRICKS]: ({ commit }) => {
    log('FETCH_BRICKS is called');

    commit(types.MUTATE_BRICKS_RECEIVED);
  },

  async [types.SAVE_FLOW](
    { commit, dispatch },
    { flow, showSnackbar = true }: { flow: FlowsState['flow']; showSnackbar: boolean },
  ): Promise<any> {
    log(`SAVE_FLOW is called, flow name is ${flow.name}`);
    await PutFlow(flow)
      .then(({ data, status }) => {
        const flowObject: FlowsState['flow'] = data;
        if (status === OK) {
          commit(types.MUTATE_FLOW_RECEIVED, flowObject);
          commit(types.MUTATE_UPDATED_FLOW_IN_FLOW_LIST, flowObject);

          if (showSnackbar) {
            handleInfoMessage('flow_updated', dispatch);
          }
        }
      })
      .catch((err) => handleErrorMessage(err, dispatch, service));
  },

  [types.SET_FLOW_IS_DIRTY]: ({ commit }, payload: Flow['isDirty']) => {
    log('SET_FLOW_IS_DIRTY is called');

    commit(types.MUTATE_FLOW_IS_DIRTY, payload);
  },

  [types.CLEAR_FLOW_STATE]: ({ commit }) => {
    commit(types.MUTATE_FLOWS_RECEIVED, '');
    commit(types.MUTATE_GET_FLOWS_STATE, '');
    commit(types.MUTATE_FLOW_RECEIVED, '');
    commit(types.MUTATE_BRICKS_RECEIVED, '');
  },

  async [types.EDIT_FLOW_NAME]({ commit, dispatch }, { id, newName, workspaceId }): Promise<any> {
    await EditFlowName(id, newName, workspaceId)
      .then(({ data, status }) => {
        if (status === OK) {
          commit(types.MUTATE_EDIT_FLOW_NAME, data);
        }
      })
      .catch((err) => handleErrorMessage(err, dispatch, service));
  },

  async [types.EDIT_FLOW_DESCRIPTION](
    { commit, dispatch },
    { id, newDescription, workspaceId },
  ): Promise<any> {
    await EditFlowDescription(id, newDescription, workspaceId)
      .then(({ data, status }) => {
        if (status && status === OK) {
          commit(types.MUTATE_EDIT_FLOW_DESCRIPTION, data);
        }
      })
      .catch((err) => handleErrorMessage(err, dispatch, service));
  },

  [types.ADD_PORT_MAPPING](
    { commit },
    { connection, mappedElement }: { connection: Connection; mappedElement: MappedElement },
  ): any {
    try {
      commit(types.MUATATE_ADD_PORT_MAPPING, { connection, mappedElement });
    } catch (e) {
      console.error(e);
    }
  },

  [types.DELETE_PORT_MAPPING](
    { commit },
    { connection, index }: { connection: Connection; index: number },
  ): any {
    try {
      if (!connection.id) {
        return;
      }
      commit(types.MUATATE_DELETE_PORT_MAPPING, {
        connection,
        index,
      });
    } catch (e) {
      console.error(e);
    }
  },

  async [types.ADD_BRICK_IN_FLOW]({ commit, dispatch }, brick: InstalledBrick): Promise<any> {
    return new Promise((resolve, reject) => {
      AddBrick(brick)
        .then(({ data, status }) => {
          if (status && status === OK) {
            commit(types.MUTATE_ADD_BRICK_IN_FLOW, data);
            resolve(data);
          }
        })
        .catch((err) => {
          handleErrorMessage(err, dispatch, service);
          reject(err);
        });
    });
  },

  [types.ADD_CONSTANT]({ commit }, { constant }: { constant: Constant }): any {
    try {
      const { id, name, value } = constant;

      if (!id || !name || !constant.type) {
        return;
      }

      commit(types.MUTATE_ADD_CONSTANT, { constant });
    } catch (e) {
      console.error(e);
    }
  },

  [types.EDIT_CONSTANT]({ commit }, { constant }: { constant: Constant }): any {
    try {
      const { id, name, value } = constant;

      if (!id || !name || !constant.type) {
        return;
      }

      commit(types.MUTATE_EDIT_CONSTANT, { constant, shouldUpdateMappingRules: true });
    } catch (e) {
      console.error(e);
    }
  },

  [types.DELETE_CONSTANT]({ commit }, { constant }: { constant: Constant }): any {
    try {
      if (!constant || !constant.id) {
        return;
      }

      commit(types.MUTATE_DELETE_CONSTANT, { constant });
    } catch (e) {
      console.error(e);
    }
  },

  [types.VALIDATE_CONSTANT](
    { commit },
    { constant, targetSchema }: { constant: Constant; targetSchema: SchemaType },
  ): any {
    try {
      const result = validateConstant(constant, targetSchema);

      commit(types.MUTATE_EDIT_CONSTANT, { constant: result });
    } catch (e) {
      console.error(e);
    }
  },

  [types.RESET_CONSTANT_VALIDATION]({ commit }, { constant }: { constant: Constant }): any {
    try {
      const result = resetConstantValidation(constant);

      commit(types.MUTATE_EDIT_CONSTANT, { constant: result });
    } catch (e) {
      console.error(e);
    }
  },

  [types.CREATE_NEW_BUFFER]({ commit }, value: SchemaType): any {
    commit(types.MUTATE_CREATE_NEW_BUFFER, value);
  },

  [types.ADD_TO_BUFFER](
    { commit },
    {
      connection,
      schema,
      bufferName,
    }: { connection: Connection; schema: SchemaType; bufferName: string },
  ): any {
    commit(types.MUTATE_ADD_TO_BUFFER, { connection, schema, bufferName });
  },

  [types.DELETE_BUFFER]({ commit }, payload: any): any {
    if (!payload.bufferId || !payload.connectionId) return;
    commit(types.MUTATE_DELETE_BUFFER, payload);
  },

  [types.ADD_FIELD_TO_MAP](
    { commit },
    { schema, itemToAddTo }: { schema: SchemaType; itemToAddTo: SchemaType },
  ): any {
    commit(types.MUTATE_ADD_FIELD_TO_MAP, {
      schema,
      itemToAddTo,
    });
  },

  [types.REMOVE_FIELD_FROM_MAP](
    { commit },
    { brick, port, item }: { brick: BrickInFlow; port: any; item: SchemaType },
  ): any {
    commit(types.MUTATE_REMOVE_FIELD_FROM_MAP, {
      brick,
      port,
      item,
    });
  },

  [types.EDIT_FIELD_IN_MAP](
    { commit },
    { brick, port, item }: { brick: BrickInFlow; port: any; item: SchemaType },
  ): any {
    commit(types.MUTATE_EDIT_FIELD_IN_MAP, {
      brick,
      port,
      item,
    });
  },

  [types.VALIDATE_BUFFER_ELEMENT](
    { commit },
    { connection, targetSchema }: { connection: Connection; targetSchema: SchemaType },
  ): any {
    commit(types.MUTATE_BUFFER_ELEMENT, { connection, targetSchema });
  },

  [types.RESET_VALIDATION_BUFFER]({ commit }, { connection }: { connection: Connection }): any {
    commit(types.MUTATE_BUFFER_ELEMENT, { connection, isReset: true });
  },

  [types.UPDATE_BRICK](
    { commit, dispatch },
    { brick, isShowSuccess }: { brick: BrickInFlow; isShowSuccess: boolean },
  ): Promise<any> {
    commit(types.MUTATE_UPDATE_BRICK_LOADING, true);
    return new Promise((resolve, reject) => {
      UpdateBrick(brick)
        .then(({ data, status }) => {
          if (status && status === OK) {
            commit(types.MUTATE_UPDATE_BRICK, data);
            commit(types.MUTATE_UPDATE_BRICK_LOADING, false);
            if (isShowSuccess) {
              const message = i18n.t('brick_saved', { name: brick.name });
              handleInfoMessage(message.toString(), dispatch);
            }
            resolve(data);
          }
        })
        .catch((err) => {
          handleErrorMessage(err, dispatch, service);
          commit(types.MUTATE_UPDATE_BRICK_LOADING, false);
          reject(err);
        });
    });
  },
  [types.CONFIGURE_APIBRICK](
    { commit, dispatch },
    {
      apiId,
      endpointId,
      brickId,
    }: { apiId: string; endpointId: string; brickId: string },
  ): Promise<any> {
    commit(types.MUTATE_UPDATE_BRICK_LOADING, true);

    return new Promise((resolve, reject) => {
      ConfigureAPIBrick(apiId, endpointId, brickId)
        .then(({ data, status }) => {
          if (status && status === OK) {
            const {
              Brick: brick,
              DeletedConnections: deletedConnections,
            }: { Brick: BrickInFlow; DeletedConnections: string[] } = data;

            commit(types.MUTATE_UPDATE_BRICK, brick);
            commit(types.MUTATE_UPDATE_BRICK_LOADING, false);

            const message = i18n.t('brick_saved', { name: data.name });

            handleInfoMessage(message.toString(), dispatch);
            resolve(data);
          }
        })
        .catch((err) => {
          handleErrorMessage(err, dispatch, service);
          commit(types.MUTATE_UPDATE_BRICK_LOADING, false);
          reject(err);
        });
    });
  },

  [types.DELETE_BRICK]({ commit, dispatch }, { brickId }: { brickId: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      DeleteBrick(brickId)
        .then(({ data, status }) => {
          if (status && status === OK) {
            commit(types.MUTATE_DELETE_BRICK_IN_FLOW, brickId);
            resolve(data);
          }
        })
        .catch((err) => {
          handleErrorMessage(err, dispatch, service);
          reject(err);
        });
    });
  },

  [types.EXPORT_FLOW]({ dispatch }, { flowId }:
    { flowId: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      ExportFlow(flowId)
        .then(({ data, status }) => {
          if (status && status === OK) {
            resolve(data);
          }
        })
        .catch((err) => {
          handleErrorMessage(err, dispatch, service);
          reject(err);
        });
    });
  },

  [types.IMPORT_FLOW]({ commit, dispatch },
    { workspaceId, file }: { workspaceId: string, file: any }): Promise<any> {
    return new Promise((resolve, reject) => {
      ImportFlow(workspaceId, file)
        .then(({ data, status }) => {
          if (status && status === OK) {
            commit(types.MUTATE_FLOW_CREATED, data);
            resolve(data);
          }
        })
        .catch((err) => {
          handleErrorMessage(err, dispatch, service);
          reject(err);
        });
    });
  },
};
