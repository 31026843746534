
import { Action, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as types from '@/store/types';

const namespace: string = 'flow';
const namespaceUser: string = 'user';

@Component
export default class PopupInfo extends Vue {
  // Props
  @Prop() flowID!: string;

  @Prop() flowName!: string;

  @Prop() flowDescription!: string;

  @Prop() image!: string;

  @Prop() dialog!: boolean;

  @Prop() flowOwner!: string;

  @Prop({ default: false }) isFlowReadOnly!: boolean;

  // Actions
  @Action(types.EDIT_FLOW_NAME, { namespace }) editFlowName: any;

  @Action(types.EDIT_FLOW_DESCRIPTION, { namespace }) editFlowDescription: any;

  @Action(types.SAVE_FLOW, { namespace }) updateFlow: any;

  // Getters
  @Getter(types.GET_FLOW, { namespace }) getFlow: any;

  @Getter(types.GET_FLOWS, { namespace }) getFlows: any;

  @Getter(types.GET_USER, { namespace: namespaceUser }) getUser: any;

  // Data
  private newName: string = '';

  private newDescription: string = '';

  private isNameEditMode: boolean = false;

  private workspaceId: string = '';

  // Vue Life Cycle Hooks
  created() {
    this.newName = this.flowName;
    this.newDescription = this.flowDescription;
    const { workspaceId } = this.$route.params;
    if (workspaceId) {
      this.workspaceId = workspaceId;
    }
  }

  private saveFlow() {
    this.isNameEditMode = false;
    this.newName = this.newName.trim();

    if (!this.newName || !this.flowID) {
      return;
    }

    if (this.flowName !== this.newName) {
      this.editFlowName({ id: this.flowID, newName: this.newName, workspaceId: this.workspaceId });
    }

    if (this.flowDescription !== this.newDescription) {
      this.editFlowDescription({
        id: this.flowID,
        newDescription: this.newDescription,
        workspaceId: this.workspaceId,
      });
    }

    this.$emit('close');
  }

  /**
   * Before closing on WITHOUT SAVE resets
   * the variables so that when being opened
   * next time, the dialog looks freshly opened
   */
  closeDialog() {
    this.newName = this.flowName;
    this.newDescription = this.flowDescription;

    this.isNameEditMode = false;

    this.$emit('close');
  }

  turnOnEditMode() {
    if (this.isFlowReadOnly) return;

    this.isNameEditMode = true;
  }
}
