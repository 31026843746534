
import {
  Component, Vue, Watch, Prop,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { GET_USER_WORKSPACES, FETCH_USER_WORKSPACES, GET_USER } from '@/store/types';

const workspaceNamespace: string = 'workspace';
const userNamespace: string = 'user';

@Component
export default class WorkspaceNav extends Vue {
  @Prop({ default: false }) inMobileView!: boolean

  @Getter(GET_USER_WORKSPACES, { namespace: workspaceNamespace }) getWorkspaces: any;

  @Getter(GET_USER, { namespace: userNamespace }) user: any;

  @Action(FETCH_USER_WORKSPACES, { namespace: workspaceNamespace }) fetchUserWorkspaces: any;

  private fetchFailedError: string = '';

  private failedToLoad: string = '';

  isDisabled(path: string): boolean {
    const currentPath = this.$router.currentRoute.path;
    return currentPath === path;
  }

  workspaceLink= (id: string) => `/flows/${id}`

  get workspaces(): any[] {
    const items = this.getWorkspaces.map((w: any) => ({
      title: w.name,
      link: this.workspaceLink(w.id),
    }));
    return items;
  }

  switchWorkspace(link: string) {
    if (this.isDisabled(link)) {
      return;
    }
    this.$router.push(link);
  }

  @Watch('user')
  async onGetUser() {
    if (this.user && this.user.id) {
      await this.fetchWorkspaces(this.user.id);
    }
  }

  routeToWorkspaces() {
    if (this.inMobileView) {
      return;
    }
    this.$router.replace('/');
  }

  async created() {
    if (this.user && this.user.id) {
      await this.fetchWorkspaces(this.user.id);
    }
  }

  async fetchWorkspaces(userId: string) {
    await this.fetchUserWorkspaces(userId).catch((err: any) => {
      if (err.message === 'Network Error') {
        this.failedToLoad = 'Connection to the Flow Manager could not be established';
      } else {
        this.failedToLoad = err.message;
      }
    });
  }
}
