import { render, staticRenderFns } from "./Locale.vue?vue&type=template&id=0b37a357&scoped=true&"
import script from "./Locale.vue?vue&type=script&lang=ts&"
export * from "./Locale.vue?vue&type=script&lang=ts&"
import style0 from "./Locale.vue?vue&type=style&index=0&id=0b37a357&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b37a357",
  null
  
)

export default component.exports