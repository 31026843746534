/* eslint-disable no-multi-assign */
/*
 Copyright 2018-present wobe-systems GmbH

 Licensed under the Apache License, Version 2.0 (the 'License');
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an 'AS IS' BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/

import * as types from '@/store/bricks/models';
import i18n from '@/middlewares/i18n';

export const validateParameter = (parameter: types.Parameter) => {
  if (parameter && parameter.constraints && parameter.constraints.validation) {
    const regx = parameter.constraints.validation;
    const { value } = parameter;
    const createRegex = new RegExp(regx);
    const result = createRegex.test(value);
    return result;
  }
  return true;
};

// Validate the parameters if brick has required to true need to have value
export const validateBricksParameters = (bricks: types.InstalledBrick[]) => {
  if (bricks && bricks.length > 0) {
    const notValid: any = [];

    bricks.map((b: any) => {
      if (b.parameters && b.parameters.length > 0) {
        const params = b.parameters;
        if (params.length < 1) {
          return [];
        }
        params.map((p: types.Parameter) => {
          if (p.constraints) {
            const info = {
              brickName: b.name,
              paramName: p.name,
            };
            if (p.constraints.required) {
              if (p.value === '') {
                notValid.push(`${String(i18n.t('required_parameter', info))}`);
              }
            }

            // validate only when NOT a secret AND unlocked
            if (p.type !== 'secret' && !p.unlocked) {
              if (!validateParameter(p)) {
                notValid.push(`${String(i18n.t('invalid_parameter', info))}`);
              }
            }
          }
          return [];
        });
        return notValid;
      }
      return [];
    });
    return notValid;
  }
  return [];
};
