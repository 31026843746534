
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Locale extends Vue {
  @Prop() color: string | undefined;

  private locale: string = 'de';

  private browserLang: string = 'de';

  mounted() {
    // eslint-disable-next-line prefer-destructuring
    this.browserLang = (navigator.language.match(/^([a-zA-Z]{2,2})/) || ['en'])[0];
    const localeStorage: string = JSON.parse(localStorage.locale || null) || '';
    this.locale = localeStorage || this.browserLang || 'de';
    this.applyLocale();
  }

  public changeLocale(newLocale: string) {
    localStorage.locale = JSON.stringify(newLocale);
    this.locale = newLocale;
    this.applyLocale();
  }

  private applyLocale() {
    this.$i18n.locale = this.locale;
  }
}
