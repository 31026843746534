
import { Action, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as types from '@/store/types';

const namespace: string = 'flow';
@Component
export default class DeleteFlow extends Vue {
  @Prop() name!: string;

  @Prop() flowID!: string;

  @Prop({ default: false }) dialog!: boolean;

  @Prop({ default: false }) isFlowReadOnly! : boolean;

  @Action(types.DELETE_FLOW, { namespace }) deleteFlow: any;

  @Action(types.CHANGE_RUN_STATE, { namespace }) changeRunState: any;

  @Getter(types.GET_RUN_STATE, { namespace }) getRunState: any;

  private newName: string = '';

  private deleteTheFlow() {
    if (this.getRunState) {
      // Before delete, Stop a flow if it's running
      this.changeRunState({ id: this.flowID, running: false }).then(() => {
        this.deleteFlow(this.flowID);
        this.$emit('close');
      });
    } else {
      this.deleteFlow(this.flowID);
      this.$emit('close');
    }
  }
}
