
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Toolbar extends Vue {
  @Prop() title!: string;

  @Prop() cflow!: string;

  private name: string = '';

  private flowname: string = '';
}
