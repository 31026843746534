
import { Component, Vue } from 'vue-property-decorator';
import { LINK_TO_DOCUMENTATION, LINK_TO_SUPPORT } from '@/scripts/shared';
import { marked } from 'marked';
// eslint-disable-next-line import/no-webpack-loader-syntax
import terms_de from '!!raw-loader!../user/terms_conditions/terms-de.md';
// eslint-disable-next-line import/no-webpack-loader-syntax
import terms_en from '!!raw-loader!../user/terms_conditions/terms-en.md';

@Component
export default class Help extends Vue {
  private items = [
    { text: 'Documentation', icon: 'mdi-text-box', link: LINK_TO_DOCUMENTATION },
    { text: 'Support', icon: 'mdi-lifebuoy', link: LINK_TO_SUPPORT },
  ];

  get terms(): string {
    if (this.$i18n.locale === 'de') {
      return marked(terms_de);
    }
    return marked(terms_en);
  }
}
